import H2 from "@material-tailwind/react/Heading2";
import LeadText from "@material-tailwind/react/LeadText";
import "assets/styles/background.css";
import "aos/dist/aos.css";
import React from "react";

/**
 * The header component for the landing page.
 * @returns The header component of the landing page to render.
 */
export default function Header() {

	return (
		<div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
			<div className="our-landing-background bg-cover bg-center absolute top-0 w-full h-full" />
			<div className="container max-w-8xl relative mx-auto">
				<div className="items-center flex flex-wrap">
					<div data-aos-duration="2000" data-aos="fade" className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
						<H2 color="white">Welcome to</H2>
						<H2 color="white">Zenith Software</H2>
						<div className="text-gray-200">
							<LeadText color="gray-200">
                                Welcome to Zenith Software! Here at Zenith,
                                we specialize in delivering dynamic,
                                innovative web and mobile solutions to our customers.
							</LeadText>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
