import React from "react";
import Title from "components/landing/Title";
import TeamCard from "components/landing/TeamCard";
import "assets/styles/teamSection.css";
import "aos/dist/aos.css";

import { teamMembers } from "./teamData";
import { useIsMobile } from "components/isMobile";

/**
 * The Team Section component for the landing page.
 * @returns The team section component of the landing page.
 */
export default function TeamSection() {
	const isMobile = useIsMobile();

	const renderTeamMember = (member, index) => {
		return <TeamCard key={member.name} layout={index % 2 === 0 ? "left" : "right"} {...member} />;
	};

	return (
		<section id="team-section" className="pt-20 pb-48">
			<div className="TeamSection"></div>
			<div data-aos="fade" className={`${isMobile ? "container" : ""} max-w-7xl mx-auto px-4`}>
				<Title heading="Meet the Team">
          Our team has experience in a wide variety of software engineering projects. Be sure to check out our
          LinkedIn, GitHub, and Instagram profiles to connect with us!
				</Title>
				<div className={isMobile ? "flex flex-wrap" : ""}>
					{teamMembers.map(renderTeamMember)}
				</div>
			</div>
		</section>
	);
}
