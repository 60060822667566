import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import Icon from "@material-tailwind/react/Icon";
import H6 from "@material-tailwind/react/Heading6";
import { scroller } from "react-scroll";
import "assets/styles/statuscard.css";

const colors = {
	blueGray: "bg-blue-gray-500",
	gray: "bg-gray-500",
	brown: "bg-brown-500",
	deepOrange: "bg-deep-orange-500",
	orange: "bg-orange-500",
	amber: "bg-amber-500",
	yellow: "bg-yellow-600",
	lime: "bg-lime-500",
	lightGreen: "bg-light-green-500",
	green: "bg-green-500",
	teal: "bg-teal-500",
	cyan: "bg-cyan-500",
	lightBlue: "bg-light-blue-500",
	blue: "bg-blue-500",
	indigo: "bg-indigo-500",
	deepPurple: "bg-deep-purple-500",
	purple: "bg-purple-500",
	pink: "bg-pink-500",
	red: "bg-red-500",
};

/**
 * The component for the status card on the landing page.
 * @param {*} props The props for the StatusCard component.
 * @param {string} props.color The color of the card.
 * @param {string} props.icon The icon of the card.
 * @param {string} props.title The title of the card.
 * @param {string} props.nav The navigation of the card.
 * @param {number} props.delay The delay of the card.
 * @returns The Status Card Component.
 */
export default function StatusCard({ color, icon, title, nav, delay }) {

	const scrollToSection = (navClass) => {
		scroller.scrollTo(navClass, {
			duration: 800,
			delay: 0,
			smooth: "easeInOutQuart",
		});
	};

	return (
		<div
			data-aos="fade-up"
			data-aos-delay={delay}
			className="w-full md:w-4/12 pt-8 px-4 flex justify-center text-center padding-cards cursor-pointer"
			onClick={() => {
				return scrollToSection(nav);
			}}
		>
			<Card className="grow:hover grow">
				<CardBody>
					<div
						className={`p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-md rounded-full ${colors[color]}`}
					>
						<Icon name={icon} size="xl" color="white" />
					</div>
					<H6 color="gray">{title}</H6>
				</CardBody>
			</Card>
		</div>
	);
}
