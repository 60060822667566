import { useState } from "react";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import NavLink from "@material-tailwind/react/NavLink";
import Dropdown from "@material-tailwind/react/Dropdown";
import Icon from "@material-tailwind/react/Icon";
import Button from "@material-tailwind/react/Button";
import { scroller } from "react-scroll";
import { DropdownLink } from "@material-tailwind/react";
import BlueLogo from "../assets/img/LogoBlue.png";
import "assets/styles/navbar.css";
import "aos/dist/aos.css";

/**
 * The default navbar for the landing page.
 * @returns The default navbar for the landing page.
 */
export default function DefaultNavbar() {

	const [ openNavbar, setOpenNavbar ] = useState(false);
	const scrollToSection = (navClass) => {
		scroller.scrollTo(navClass, {
			duration: 800,
			delay: 0,
			smooth: "easeInOutQuart",
		});
	};

	return (
		<div data-aos="fade-down">
			<Navbar color="transparent" navbar>
				<NavbarContainer>
					<NavbarWrapper>
						<a
							href="https://zenithsoftware.dev"
							target="_self"
							rel="noreferrer"
						>

							<div className="flex">

								<img src={BlueLogo} alt="Zenith Software Development Logo" className="logo"></img>

								<div className="zenith-words">
									<NavbarBrand>Zenith Software</NavbarBrand>
								</div>

							</div>
						</a>
						<NavbarToggler
							onClick={() => {
								setOpenNavbar(!openNavbar);
							}}
							color="white"
						/>
					</NavbarWrapper>

					<NavbarCollapse open={openNavbar}>
						<Nav>
							<div className="flex flex-row z-50 lg:flex-row lg:items-center" style={{ justifyContent: "right" }}>
								<NavLink
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										return scrollToSection("AboutUs");
									}}
									style={{ paddingRight:0, paddingLeft: 0 }}
								>
									<Button
										color="white"
										size="sm"
										ripple="light"
									>
										<Icon name="description" size="2x1" />
										About
									</Button>
								</NavLink>
								<NavLink
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										return scrollToSection("flex-auto p-5 lg:p-10");
									}}
									style={{ paddingRight:4, paddingLeft: 0 }}
								>
									<Button
										color="white"
										size="sm"
										ripple="light"
										buttonText="CONTACT US"
									>
										<Icon name="question_answer" size="2x1" />
										Contact
									</Button>
								</NavLink>
								<NavLink
									target="_blank"
									rel="noreferrer"
									style={{ paddingRight:0, paddingLeft: 0 }}
								>
									<Dropdown
										// className="px-0"
										color="white"
										buttonText="SOCIALS"
										buttonType="link"
										size="sm"
										ripple="light"
										style={{ paddingLeft: 8 }}

									>
										<DropdownLink
											href="https://www.linkedin.com/company/zenith-software-dev"
											color="lightBlue"
											ripple="light"
											target="_blank"
										>
											LinkedIn
										</DropdownLink>
										<DropdownLink
											href="https://twitter.com/zenithsoftware_"
											color="lightBlue"
											ripple="light"
											target="_blank"
										>
											Twitter
										</DropdownLink>
										<DropdownLink
											href="https://www.instagram.com/zenithsoftwaredev/"
											color="lightBlue"
											ripple="light"
											target="_blank"
										>
											Instagram
										</DropdownLink>
										<DropdownLink
											href="https://www.facebook.com/Zenith-Software-100289855796121"
											color="lightBlue"
											ripple="light"
											target="_blank"
										>
											Facebook
										</DropdownLink>
									</Dropdown>
								</NavLink>
							</div>
						</Nav>
					</NavbarCollapse>
				</NavbarContainer>
			</Navbar>
		</div>
	);
}

