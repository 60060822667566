import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import Icon from "@material-tailwind/react/Icon";
import H4 from "@material-tailwind/react/Heading4";
import H6 from "@material-tailwind/react/Heading6";
import LeadText from "@material-tailwind/react/LeadText";
import Paragraph from "@material-tailwind/react/Paragraph";
import StatusCard from "components/landing/StatusCard";
import GroupPhoto from "assets/img/GroupPhoto.png";
import "aos/dist/aos.css";
import React from "react";


/**
 * The component of the working section, including the mission statement and a photo of the team.
 * @returns The working section of the landing page.
 */
export default function WorkingSection() {

	return (

		<section className="pb-20 bg-gray-100 -mt-32">
			<div className="container max-w-7xl mx-auto px-4">
				<div className="flex flex-wrap relative z-50">

					<StatusCard
						color="lightBlue"
						icon="info"
						title="About Us"
						nav="AboutUs"
						delay={0}
					>
					</StatusCard>

					<StatusCard
						color="lightBlue"
						icon="person"
						title="Meet The Team"
						nav="TeamSection"
						delay={100}
					>
					</StatusCard>

					<StatusCard
						color="lightBlue"
						icon="message"
						title="Contact Us"
						nav="ContactSection"
						delay={200}
					>
					</StatusCard>
				</div>

				<div className="AboutUs"></div>

				<div className="flex flex-wrap items-center mt-32">
					<div className="w-full md:w-5/12 px-4 mx-auto">
						<div
							data-aos="fade"
							className=
								"text-blue-gray-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
						>
							<Icon name="people" size="3xl" />
						</div>
						<H4 data-aos="fade" color="gray">Our Mission</H4>
						<LeadText data-aos="fade" color="blueGray">
                            Zenith Software is a Baton Rouge-based software development firm
                            that has innovation in mind. Our talented and efficient team makes
                            sure to deliver the most high-quality ideas and solutions at a
                            consistent and impressive rate.
						</LeadText>
						<LeadText data-aos="fade" color="blueGray">
                            Success is found at the top… We’ll see you there.
						</LeadText>
					</div>

					<div data-aos="fade" className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
						<Card>
							<CardImage alt="The team in front of LSU's Clock Tower" src={GroupPhoto} />
							<CardBody>
								<H6 color="gray">Hard at Work</H6>
								<Paragraph color="blueGray">
                                    The Zenith Software team is working hard at Louisiana State University
                                    to further their education while forming new connections with business
                                    professionals.
								</Paragraph>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		</section>
	);
}
