import Landing from "pages/Landing";

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

import "assets/styles/tailwind.css";
import "assets/styles/bios.css";

import AOS from "aos";
import { useEffect } from "react";

/**
 * The Whole Application.
 * @returns The App component to render.
 */
function App() {
	useEffect(() => {
		AOS.init({ duration: 700 });
	}, []);

	return (
		<Landing />
	);
}

export default App;
