import React from "react";
import Form from "components/landing/Form";
import "aos/dist/aos.css";

/**
 * The contact section of the landing page.
 * @returns The contact us section of the landing page.
 */
export default function ContactSection() {

	return (
		<section id="contact-section" className="pb-20 relative block bg-gray-100">
			<div data-aos="fade" data-aos-duration="1000" className="ContactSection">
				<div className="container max-w-7xl mx-auto px-4 pt-20">
					<Form />
				</div>
			</div>
		</section>
	);
}
