import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/landing/Header";
import WorkingSection from "components/landing/WorkingSection";
import TeamSection from "components/landing/TeamSection";
import ContactSection from "components/landing/ContactSection";
import ScrollTopButton from "components/landing/ScrollTopButton";
import "assets/styles/entirePage.css";


/**
 * The landing page of the website.
 * @returns The landing page with its components.
 */
export default function Landing() {
	return (
		<div className="hideOverflow">
			<div className="absolute w-full z-20">
				<DefaultNavbar />
			</div>
			<main>
				<Header />
				<WorkingSection />
				<TeamSection />
				<ContactSection />
				<ScrollTopButton />
			</main>
			<DefaultFooter />
		</div>
	);
}
