import H3 from "@material-tailwind/react/Heading3";
import Paragraph from "@material-tailwind/react/Paragraph";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import Button from "@material-tailwind/react/Button";
import LeadText from "@material-tailwind/react/LeadText";
import emailjs from "emailjs-com";
import React from "react";


class Form extends React.Component {

	state = {
		nameColor: true,
		emailColor: true,
		messageColor: true,
		sentColor: true
	};

	toggleName = () => {
		this.setState((state) => {
			return { nameColor:  !state.nameColor };
		});
	};

	toggleEmail = () => {
		this.setState((state) => {
			return { emailColor:  !state.emailColor };
		});
	};

	toggleMessage = () => {
		this.setState((state) => {
			return { messageColor:  !state.messageColor };
		});
	};

	toggleSentColor = () => {
		this.setState((state) => {
			return { sentColor: !state.sentColor };
		});
	};

	getNameColor = () => {
		return this.state.nameColor ? "lightBlue" : "red";
	};
	getEmailColor = () => {
		return this.state.emailColor ? "lightBlue" : "red";
	};
	getMessageColor = () => {
		return this.state.messageColor ? "lightBlue" : "red";
	};
	getSentColor = () => {
		return this.state.sentColor ? "red" : "green";
	};
	getBtnVis = () => {
		return this.state.sentColor ? "inline" : "none";
	};

	/**
	 * The render method for the contact section.
	 * @returns The form for the contact section to be rendered.
	 */
	render() {

		const nameFieldColor = this.getNameColor();
		const emailFieldColor = this.getEmailColor();
		const messageFieldColor = this.getMessageColor();
		const sentColor = this.getSentColor();
		const visibility = this.getBtnVis();

		const sendEmail = (e) => {
			e.preventDefault();

			if (document.getElementById("name").value === "" ||
			document.getElementById("email").value === "" ||
			document.getElementById("message").value === ""
			) {
				document.getElementById("error").textContent = "Please fill out all fields to send a message";

				if (document.getElementById("name").value === "") {
					document.getElementById("name").focus();
					this.toggleName();
				} else if (document.getElementById("email").value === "") {
					document.getElementById("email").focus();
					this.toggleEmail();
				} else if (document.getElementById("message").value === "") {
					document.getElementById("message").focus();
					this.toggleMessage();
				}

			}else {
				document.getElementById("error").textContent = "";

				emailjs.sendForm("service_bgztbtm", "template_26u333e", document.getElementById("emailForm"), "user_mnn1kQUYgGyyu0PPDKMN4")
					.then((result) => {
						console.log(result.text);
					}, (error) => {
						console.log(error.text);
					});

				document.getElementById("name").value = "";
				document.getElementById("email").value = "";
				document.getElementById("message").value = "";
				document.getElementById("sendButton").textContent = "Messsage Sent";

				this.toggleSentColor();
				document.getElementById("error").textContent = "Thank you! Your message has been sent. We will get back to you within 24 hours";

			}
		};
		return (
			<div className="flex flex-wrap justify-center">
				<div className="w-full lg:w-8/12 px-4">
					<div className="relative flex flex-col min-w-0 break-words w-full mb-6">
						<div className="flex-auto p-5 lg:p-10">
							<div className="w-full text-center">
								<H3 color="gray">Contact Us</H3>
								<Paragraph color="blueGray">
                                    Complete this form and we will get back to you
                                    in 24 hours.
								</Paragraph>
							</div>
							<form id="emailForm" onSubmit={sendEmail}>
								<div className="flex gap-8 mt-16 mb-12">
									<Input
										id="name"
										type="text"
										placeholder="Full Name"
										color= {nameFieldColor}
										name="user_name"
									/>
									<Input
										id="email"
										type="email"
										placeholder="Email Address"
										color= {emailFieldColor}
										name="user_email"
									/>
								</div>

								<Textarea id="message" color= {messageFieldColor} placeholder="Message" name="message"/>

								<div className="flex justify-center text-center">
									<LeadText
										id="error"
										color={sentColor}
									>
									</LeadText>
								</div>

								<div className="flex justify-center mt-10">

									<Button id="sendButton" color="lightBlue" ripple="light" style={{ display: visibility }} >
                                        Send Message
									</Button>
								</div>

							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


/**
 * For some reason we decided to create a React component for the contact section of the landing page.
 * Then instead of exporting the component, we exported the function that creates the component.
 * We are so smart.
 * @returns The contact section of the landing page.
 */
export default function ContactSection() {
	return <Form/>;
}
