import Image from "@material-tailwind/react/Image";
import H6 from "@material-tailwind/react/Heading6";
import Paragraph from "@material-tailwind/react/Paragraph";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import "aos/dist/aos.css";
import React from "react";
import { useIsMobile } from "components/isMobile";

/**
 * The component for the team card on the landing page.
 * @param {*} props The props for the LandingTeamCard component.
 * @param {string} props.img The image of the team member.
 * @param {string} props.name The name of the team member.
 * @param {string} props.position The position of the team member.
 * @param {string} props.linkedIn The LinkedIn profile of the team member.
 * @param {string} props.gitHub The GitHub profile of the team member.
 * @param {string} props.instagram The Instagram profile of the team member.
 * @param {string} props.bio The biography of the team member.
 * @param {string} props.layout The layout of the card ('left', 'right', or 'mobile').
 * @returns The Card to render for the team member.
 */
export default function LandingTeamCard({ img, name, position, linkedIn, gitHub, instagram, bio, layout }) {

	const isMobile = useIsMobile();

	const socialButtons = (
		<div className="flex items-center justify-center">
			{linkedIn && (
				<a href={linkedIn} target="_blank" rel="noopener noreferrer">
					<Button color="lightBlue" buttonType="link" ripple="dark" rounded iconOnly>
						<Icon family="font-awesome" name="fab fa-linkedin" size="lg" />
					</Button>
				</a>
			)}
			{gitHub && (
				<a href={gitHub} target="_blank" rel="noopener noreferrer">
					<Button color="black" buttonType="link" ripple="dark" rounded iconOnly>
						<Icon family="font-awesome" name="fab fa-github" size="lg" />
					</Button>
				</a>
			)}
			{instagram && (
				<a href={instagram} target="_blank" rel="noopener noreferrer">
					<Button color="pink" buttonType="link" ripple="dark" rounded iconOnly>
						<Icon family="font-awesome" name="fab fa-instagram" size="lg" />
					</Button>
				</a>
			)}
		</div>
	);

	const imageSection = () => {
		return (
			<div className={`px-6 ${isMobile ? "mobile-image" : ""}`}>
				<div className={isMobile ? "px-12" : "px-24"}>
					<Image src={img} alt={name} raised />
				</div>
				<div className="pt-6 text-center">
					<H6 color="gray">{name}</H6>
					<Paragraph color="blueGray">{position}</Paragraph>
					{(linkedIn || gitHub || instagram) && socialButtons}
				</div>
			</div>
		);
	};

	const bioSection = (
		<div className="flex container items-center top-1/2">
			<div className={`biotext ${layout === "left" ? "pr-29" : "pl-29"} pb-34`}>
				<Paragraph color="blueGray">{bio}</Paragraph>
			</div>
		</div>
	);

	if (isMobile) {
		return (
			<div data-aos="fade" className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
				{imageSection()}
				<div className="text-center px-6">
					<Paragraph color="black" style={{ paddingTop: 16 }}>{bio}</Paragraph>
				</div>
			</div>
		);
	}

	return (
		<div data-aos={layout === "left" ? "fade-right" : "fade-left"} className="flex px-4 lg:mb-0 mb-12">
			{layout === "left" ? (
				<>
					{imageSection()}
					{bioSection}
				</>
			) : (
				<>
					{bioSection}
					{imageSection()}
				</>
			)}
		</div>
	);
}
