import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import reportWebVitals from "reportWebVitals";
import ReactGA from "react-ga4";

if (window.location.hostname !== "localhost") {
	ReactGA.initialize("G-DZ58WJHB4Y");
	console.log("CONNECTED");
} else {
	console.log("ANALYTICS NOT REPORTED - USER ON LOCALHOST");
}
ReactGA.send("pageview");

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
